import React from 'react';


function Footer(props) {
    return(
        <footer>
            <div className=" primary-color p-4 m-0">
                All Rights Reserved Kaleigh and Oliver Inc. (2020)
            </div>
        </footer>
    );
}
  
export default Footer;
  